@font-face {
    font-family: 'Gropled';
    src: url('./fonts/GropledBold.woff2') format('woff2');
    src: url('./fonts/GropledBold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-Medium.woff2') format('woff2');
    src: url('./fonts/Montserrat-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-SemiBold.woff2') format('woff2');
    src: url('./fonts/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('./fonts/Montserrat-Bold.woff2') format('woff2');
    src: url('./fonts/Montserrat-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

html, body {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
    overflow: hidden;
    touch-action: none;
}

main {
    position: relative;
}

[role="tabpanel"]::-webkit-scrollbar {
    width: 11px;
    background: #F4F4F4;
    border-radius: 10px;
}

[role="tabpanel"]::-webkit-scrollbar-thumb {
    background: #7348FF;
    border-radius: 10px;
}

.radio-button__label {
    font-size: 24px;
    position: relative;
    padding-left: 25px;
}

.radio-button__label::before {
    content: '';
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 3px solid #B9B9B9;
    background: #fff;
    position: absolute;
    top: 2px;
    left: -16px;
}

.radio-button__label::after {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #fff;
    position: absolute;
    top: 8px;
    left: -10px;
}

.radio-button__input {
    width: 0;
    height: 0;
    position: absolute;
    z-index: -1;
    opacity: 0;
}

.radio-button__input:checked + .radio-button__label::before {
    border-color: #7348FF;
}

.radio-button__input:checked + .radio-button__label::after {
    background: #7348FF;
}