.my-keyboard {
    font-family: 'Montserrat',
    'sans-serif',
    '"Helvetica Neue"',
    'Arial',
    'sans-serif',
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"';
    max-width: 980px;
    background: transparent;
    color: #000000;
}

.my-keyboard .hg-row:not(:last-child) {
    margin-bottom: 12px;
}

.my-keyboard .hg-row .hg-button:not(:last-child) {
    margin-right: 12px;
}

.my-keyboard .hg-button {
    min-width: 70px;
    height: 70px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    border-bottom: none;
    font-weight: 700;
    font-size: 32px;
    flex-grow: unset;
}

.my-keyboard .hg-button.hg-standardBtn {
    width: 70px;
}

.my-keyboard .hg-button-bksp span,
.my-keyboard .hg-button-enter span,
.my-keyboard .hg-button-shiftLeft span, 
.my-keyboard .hg-button-shiftRight span, 
.my-keyboard .hg-button-arrowleft span,
.my-keyboard .hg-button-arrowright span,
.my-keyboard .hg-button-space span,
.my-keyboard .hg-button-close span,
.my-keyboard .hg-button-numpadadd span {
    display: none;
}

.my-keyboard .hg-button-bksp,
.my-keyboard .hg-button-bksp.hg-activeButton {
    width: 152px;
    background: #F3F1F6 url('../../assets/images/back.svg') center center no-repeat;
}

.my-keyboard .hg-button-enter,
.my-keyboard .hg-button-enter.hg-activeButton {
    width: 152px;
    background: #F3F1F6 url('../../assets/images/enter.svg') center center no-repeat;
}

.my-keyboard .hg-button-shiftLeft,
.my-keyboard .hg-button-shiftRight {
    background: #F3F1F6 url('../../assets/images/shift.svg') center center no-repeat;
}

.my-keyboard .hg-button-arrowleft,
.my-keyboard .hg-button-arrowleft.hg-activeButton {
    background: #F3F1F6 url('../../assets/images/left.svg') center center no-repeat;
}

.my-keyboard .hg-button-arrowright,
.my-keyboard .hg-button-arrowright.hg-activeButton {
    background: #F3F1F6 url('../../assets/images/right.svg') center center no-repeat;
}

.my-keyboard .hg-button-lang,
.my-keyboard .hg-button-langRu  {
    min-width: 152px;
    color: #7348FF;
    background: #F3F1F6;
}

.my-keyboard .hg-button-space {
    width: 100%;
}

.my-keyboard .hg-button-close,
.my-keyboard.my-keyboard-number .hg-button-close {
    min-width: 152px;
    background: #F3F1F6 url('../../assets/images/keyboard.svg') center center no-repeat;
}

.my-keyboard.my-keyboard-number .hg-button-numpadadd {
    height: 70px;
    background: #F3F1F6 url('../../assets/images/plus.svg') center center no-repeat;
}

.my-keyboard.hg-layout-langShift .hg-button-shiftRight,
.my-keyboard.hg-layout-shift .hg-button-shiftLeft {
    background: #7348FF url('../../assets/images/shift-light.svg') center center no-repeat;;
}

.my-keyboard [data-skbtn="_"],
.my-keyboard [data-skbtn="."],
.my-keyboard [data-skbtn="-"],
.my-keyboard [data-skbtn="@"] {
    color: #7348FF;
    background: #F3F1F6;
    font-size: 24px;
}

.my-keyboard-number {
    max-width: 325px;
}

.my-keyboard.my-keyboard-number .hg-button-bksp,
.my-keyboard.my-keyboard-number .hg-button-enter,
.my-keyboard.my-keyboard-number .hg-button-close {
    min-width: 70px;
    width: 70px;
}

.my-keyboard.hg-layout-disArrows .hg-button-arrowright {
    background: #e2e2e6 url('../../assets/images/right-dis.svg') center center no-repeat;
}

.my-keyboard.hg-layout-disArrows .hg-button-arrowleft {
    background: #e2e2e6 url('../../assets/images/left-dis.svg') center center no-repeat;
}

.my-keyboard.hg-layout-disArrows .hg-button-numpadadd {
    background: #e2e2e6 url('../../assets/images/plus-dis.svg') center center no-repeat;
}